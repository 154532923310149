/* eslint-disable no-mixed-operators */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
import { AUDIO_TYPE, INITIAL_TYPE } from "configs/jobs/constants";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { store } from "store";
import { getQuestionsForCandidate } from "store/modules/сandidates/selectors";

const isAudioContextSupported = () => "AudioContext" in window || "webkitAudioContext" in window;

const useDetectMute = (isRecording, questionId, audioDeviceId) => {
  const [hasAudio, setHasAudio] = useState(true);
  const [displayAudioAnimation, setDisplayAudioAnimation] = useState(false);
  const intervalId = useRef();
  const [hasAudioArray, setHasAudioArray] = useState([]);
  const [audioContextSupported, setaudioContextSupported] = useState(true);
  const audioContextRef = useRef();
  const [lastAudioDetected, setLastAudioDetected] = useState(moment());

  const questionsForCandidate = getQuestionsForCandidate(store.getState());

  const currentQuestion = useMemo(
    () => questionsForCandidate.find(item => item.key === questionId),
    [questionId, questionsForCandidate]
  );

  const closeAudioContext = async () => {
    if (audioContextRef.current) {
      if (audioContextRef.current.state !== "closed") {
        try {
          await audioContextRef.current.close();
        } catch (error) {
          console.error("Error closing AudioContext:", error);
        } finally {
          audioContextRef.current = null;
        }
      } else {
        audioContextRef.current = null;
      }
    }
  };

  const handleChangeDevice = async () => {
    const isRetrievingMediaDevicesAllowed = typeof navigator?.mediaDevices?.ondevicechange === "object" ||
    typeof navigator?.mediaDevices?.ondevicechange === "function";

    if (([INITIAL_TYPE, AUDIO_TYPE].includes(currentQuestion?.answer_type) ||
      !currentQuestion?.answer_type) && isRetrievingMediaDevicesAllowed) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio:
        { deviceId: audioDeviceId } });

      // Close existing AudioContext if it exists
      await closeAudioContext();

      audioContextRef.current = new AudioContext();
      if (!isAudioContextSupported() ||
       !audioContextRef.current) return setaudioContextSupported(false);
      setaudioContextSupported(true);
      const analyzer = audioContextRef.current.createAnalyser();
      analyzer.fftSize = 512;
      analyzer.smoothingTimeConstant = 0.1;
      const sourceNode = audioContextRef.current.createMediaStreamSource(stream);
      sourceNode.connect(analyzer);

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      intervalId.current = setInterval(() => {
        const fftBins = new Float32Array(analyzer.frequencyBinCount);
        analyzer.getFloatFrequencyData(fftBins);
        const audioPeakDB = Math.max(...fftBins);

        const frequencyRangeData = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(frequencyRangeData);
        const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
        const audioMeter = Math.sqrt(sum / frequencyRangeData.length);

        if (isRecording) {
          if (audioMeter === 0) {
            setHasAudioArray(prev => ([...prev, false]));
          } else {
            setHasAudioArray(prev => ([...prev, true]));
          }
        } else {
          setHasAudioArray([]);
        }
        if ((audioMeter === 0 && (String(audioPeakDB).includes("Infinity")) || Number(audioPeakDB) < -100)) {
          setHasAudio(false);
        } else {
          setHasAudio(true);
          setLastAudioDetected(moment());
        }

        if (audioPeakDB > -50) {
          setDisplayAudioAnimation(true);
        } else {
          setDisplayAudioAnimation(false);
        }
      }, 100);
    }
  };

  if (typeof navigator?.mediaDevices?.ondevicechange === "object"
      && !isMobile) {
    navigator.mediaDevices.ondevicechange = () => {
      if (process.env.REACT_APP_DETECT_MUTE === "false" || isMobile) return;
      handleChangeDevice();
    };
  }

  useEffect(() => {
    if ((process.env.REACT_APP_DETECT_MUTE === "false" || isMobile)) return;
    handleChangeDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording, currentQuestion, audioDeviceId]);

  useEffect(() => () => {
    closeAudioContext();
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      setaudioContextSupported(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => () => {
    if (audioContextRef.current && !isMobile && process.env.REACT_APP_DETECT_MUTE === "true") {
      audioContextRef?.current?.close?.();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (process.env.REACT_APP_DETECT_MUTE === "false") {
    return {
      hasAudio: true
    };
  }

  const showNoAudio = moment(moment()).diff(lastAudioDetected, "seconds") > 5;

  return {
    hasAudio: hasAudio === true ? hasAudio : !showNoAudio,
    displayAudioAnimation: audioContextSupported ? displayAudioAnimation : false,
    fullVideoHasAudio: audioContextSupported
      ? (hasAudioArray.filter(a => a).length / hasAudioArray.length) > 0.1 : true,
    audioContextSupported,
    delayedShowAudioAnimation: showNoAudio
  };
};

export default useDetectMute;
